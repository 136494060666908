import {json, LoaderFunctionArgs, redirect} from '@remix-run/node';
import {getActiveGiveawaysForPartner, getPartnerFromSlug} from '~/services/partner-service.server';
import {builderApiKey, getContent} from '~/builder-service';
import {useLoaderData} from '@remix-run/react';
import {Content, isEditing, isPreviewing} from '@builder.io/sdk-react';
import Error404 from '~/components/Error404';
import builderComponents from '~/components/builder/builderComponents';
import {builderContent} from '@styled-system/recipes';

const redirects: {[k: string]: string} = {
  honda: 'https://fanathem.com/promotions/6412ab98-0e3b-4054-a8a7-f5914f82e5c1/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=HondaCB1000R',
  cmmg: 'https://fanathem.com/promotions/2ef6b542-b1e7-4f2a-9bd0-39f32e4fa660/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=CMMG',
  ddh9: 'https://fanathem.com/promotions/293a12e4-1163-4edc-a98b-b510fe5b0205/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=DDH9',
  mr556: 'https://fanathem.com/promotions/e8624d94-2112-4c3d-a0bc-b4df1a13552f/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=HKmr556',
  satrp: 'https://fanathem.com/promotions/5434a281-19cf-45be-bc29-f38f7b377f1a/fanathen?utm_source=FAN&utm_medium=SMS&utm_campaign=SATRP',
  edcx9: 'https://fanathem.com/promotions/931b63de-462a-4c1b-9bf6-279771c777df/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=WilcoEDCx90125',
  fenix8: 'https://fanathem.com/promotions/315a9d4c-271f-4880-83de-4765ff07e057?utm_source=FAN&utm_medium=SMS&utm_campaign=Fenix8',
  rand1911: 'https://fanathem.com/promotions/7b47fe16-1998-42d4-8967-e9d6125f1dca/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=Rand1911',
  staccatohdp4: 'https://fanathem.com/promotions/44de77c1-c616-42ac-b166-640c1a85f2ba/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=StaccatoHDP4',
  czs2oj: 'https://fanathem.com/promotions/f2381aa2-791c-470f-90a9-d1aa41821e60/fanathem?utm_source=FAN&utm_medium=SMS&utm_campaign=CZS2OJ',
  wylde: 'https://fanathem.com/promotions/9cae337d-1153-4dfc-af23-f878146d2b8d?utm_source=FAN&utm_medium=SMS&utm_campaign=Wylde',
}

export const loader = async ({request, params}: LoaderFunctionArgs) => {
  const slug = params['*'];

  const trimmedSlug = slug?.toLowerCase()?.trim()
  const redirectTo = trimmedSlug && redirects[trimmedSlug];

  if (redirectTo) {
    return redirect(redirectTo);
  }

  const partner = await getPartnerFromSlug(slug as string)

  const page = await getContent({
    model: 'page',
    userAttributes: {
      urlPath: ('/' + slug),
      partnerId: partner?.id,
    },
  });

  const searchParams = new URL(request.url).searchParams;
  const isEditingOrPreviewing = isEditing(searchParams) || isPreviewing(searchParams);

  if (page || isEditingOrPreviewing) {
    return json({
      content: page,
    })
  }

  if (!partner) {
    throw new Response(null, {
      status: 404,
      statusText: "Not Found",
    });
  }

  const giveaways = await getActiveGiveawaysForPartner(partner.id);

  if (giveaways.length == 1) {
    return redirect(
      `/promotions/${giveaways[0].id}/${slug}`
    )
  }

  return redirect(
    `/partners/${partner.id}`
  );
}

const BuilderPage = () => {
  const {content} = useLoaderData<typeof loader>();

  if (!content && !isPreviewing()) {
    return <Error404/>
  }

  return (
    <div className={builderContent()}>
      <Content
        apiKey={builderApiKey}
        model="page"
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
        content={content as any}
        customComponents={builderComponents}
      />
    </div>
  );
};

export default BuilderPage;
